export const COUNT_PER_PAGE = 20;
export const PREFECTURES: {
  [key: string]: string;
}[] = [
    {
      "en": "Hokkaido",
      "ja": "北海道",
    },
    {
      "en": "Aomori",
      "ja": "青森",
    },
    {
      "en": "Iwate",
      "ja": "岩手",
    },
    {
      "en": "Miyagi",
      "ja": "宮城",
    },
    {
      "en": "Akita",
      "ja": "秋田",
    },
    {
      "en": "Yamagata",
      "ja": "山形",
    },
    {
      "en": "Fukushima",
      "ja": "福島",
    },
    {
      "en": "Ibaraki",
      "ja": "茨城",
    },
    {
      "en": "Tochigi",
      "ja": "栃木",
    },
    {
      "en": "Gunma",
      "ja": "群馬",
    },
    {
      "en": "Saitama",
      "ja": "埼玉",
    },
    {
      "en": "Chiba",
      "ja": "千葉",
    },
    {
      "en": "Tokyo",
      "ja": "東京",
    },
    {
      "en": "Kanagawa",
      "ja": "神奈川",
    },
    {
      "en": "Niigata",
      "ja": "新潟",
    },
    {
      "en": "Toyama",
      "ja": "富山",
    },
    {
      "en": "Ishikawa",
      "ja": "石川",
    },
    {
      "en": "Fukui",
      "ja": "福井",
    },
    {
      "en": "Yamanashi",
      "ja": "山梨",
    },
    {
      "en": "Nagano",
      "ja": "長野",
    },
    {
      "en": "Gifu",
      "ja": "岐阜",
    },
    {
      "en": "Shizuoka",
      "ja": "静岡",
    },
    {
      "en": "Aichi",
      "ja": "愛知",
    },
    {
      "en": "Mie",
      "ja": "三重",
    },
    {
      "en": "Shiga",
      "ja": "滋賀",
    },
    {
      "en": "Kyoto",
      "ja": "京都",
    },
    {
      "en": "Osaka",
      "ja": "大阪",
    },
    {
      "en": "Hyogo",
      "ja": "兵庫",
    },
    {
      "en": "Nara",
      "ja": "奈良",
    },
    {
      "en": "Wakayama",
      "ja": "和歌山",
    },
    {
      "en": "Tottori",
      "ja": "鳥取",
    },
    {
      "en": "Shimane",
      "ja": "島根",
    },
    {
      "en": "Okayama",
      "ja": "岡山",
    },
    {
      "en": "Hiroshima",
      "ja": "広島",
    },
    {
      "en": "Yamaguchi",
      "ja": "山口",
    },
    {
      "en": "Tokushima",
      "ja": "徳島",
    },
    {
      "en": "Kagawa",
      "ja": "香川",
    },
    {
      "en": "Ehime",
      "ja": "愛媛",
    },
    {
      "en": "Kochi",
      "ja": "高知",
    },
    {
      "en": "Fukuoka",
      "ja": "福岡",
    },
    {
      "en": "Saga",
      "ja": "佐賀",
    },
    {
      "en": "Nagasaki",
      "ja": "長崎",
    },
    {
      "en": "Kumamoto",
      "ja": "熊本",
    },
    {
      "en": "Oita",
      "ja": "大分",
    },
    {
      "en": "Miyazaki",
      "ja": "宮崎",
    },
    {
      "en": "Kagoshima",
      "ja": "鹿児島",
    },
    {
      "en": "Okinawa",
      "ja": "沖縄",
    },
  ];
export const TAG_COLORS: {
  [key: string]: string;
} = {
  "Sweet": "#f8bbd0",
  "Acid": "#f0f4c3",
  "Umami": "#d7ccc8",
  "Bitter": "#c8e6c9",
  "Astringent": "#d1c4e9",
  "Gamey": "#ffcdd2",
  "Mellow": "#fff9c4",
  "Mild": "#b3e5fc",
  "Complex": "#e1bee7",
  "Creamy": "#ffecb3",
  "Crisp": "#dcedc8",
  "Dry": "#e0e0e0",
  "Full-bodied": "#bcaaa4",
  "Smooth": "#bbdefb",
  "Caramel": "#d7ccc8",
  "Unripe/Green Banana": "#fff9c4",
  "Chestnut": "#bcaaa4",
  "White Pepper": "#eeeeee",
  "Mineral": "#cfd8dc",
  "Butter": "#fff59d",
  "Cream Cheese": "#ffecb3",
  "Rice Flour": "#fafafa",
  "Cooked Rice": "#efebe9",
  "Floral": "#f8bbd0",
  "Blossoms": "#fce4ec",
  "Rose": "#ffebee",
  "Jasmine": "#fffde7",
  "Fruity": "#c8e6c9",
  "Melon": "#e8f5e9",
  "Lychee": "#f3e5f5",
  "Pear": "#e8f5e9",
  "Green Apple": "#c8e6c9",
  "Banana": "#fffde7",
  "Berry": "#ffcdd2",
  "Pineapple": "#fff9c4",
  "Citrus": "#fff9c4",
  "Herbaceous": "#c8e6c9",
  "Grass": "#a5d6a7",
  "Earthy": "#d7ccc8",
  "Mushroom": "#d7ccc8",
  "Oak": "#d7ccc8",
  "Vanilla": "#ffecb3",
  "Spicy": "#ffccbc",
  "Anise": "#ffab91",
  "Pepper": "#ffab91",
  "Cinnamon": "#ffab91",
  "Almond": "#ffe082",
  "Honey": "#ffecb3",
  "Chocolate": "#bcaaa4",
  "Red fruit": "#ffcdd2",
  "Black fruit": "#b0bec5",
  "Tree fruit": "#d7ccc8",
  "Citrus Fruit": "#fff9c4",
  "Tropical fruit": "#c8e6c9",
  "Vegetal": "#dcedc8",
  "Oaky": "#ffecb3",
  "Spices": "#ffccbc",
  "Ageing": "#cfd8dc",
  "Yeasty": "#d7ccc8",
  "Dried fruit": "#ffe0b2",
}
export const TAG_TRANSLATION: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  "Sweet": {
    "en": "Sweet",
    "ja": "甘味",
  },
  "Acid": {
    "en": "Acid",
    "ja": "酸味",
  },
  "Umami": {
    "en": "Umami",
    "ja": "旨味",
  },
  "Bitter": {
    "en": "Bitter",
    "ja": "苦味",
  },
  "Astringent": {
    "en": "Astringent",
    "ja": "渋味",
  },
  "Gamey": {
    "en": "Gamey",
    "ja": "野性的な香り",
  },
  "Mellow": {
    "en": "Mellow",
    "ja": "まろやか",
  },
  "Mild": {
    "en": "Mild",
    "ja": "穏やか",
  },
  "Complex": {
    "en": "Complex",
    "ja": "複雑",
  },
  "Creamy": {
    "en": "Creamy",
    "ja": "クリーミー",
  },
  "Crisp": {
    "en": "Crisp",
    "ja": "キレのある",
  },
  "Dry": {
    "en": "Dry",
    "ja": "辛口",
  },
  "Full-bodied": {
    "en": "Full-bodied",
    "ja": "濃醇",
  },
  "Smooth": {
    "en": "Smooth",
    "ja": "なめらか",
  },
  "Caramel": {
    "en": "Caramel",
    "ja": "カラメル",
  },
  "Unripe/Green Banana": {
    "en": "Unripe/Green Banana",
    "ja": "青バナナ",
  },
  "Chestnut": {
    "en": "Chestnut",
    "ja": "栗",
  },
  "White Pepper": {
    "en": "White Pepper",
    "ja": "白胡椒",
  },
  "Mineral": {
    "en": "Mineral",
    "ja": "ミネラル",
  },
  "Butter": {
    "en": "Butter",
    "ja": "バター",
  },
  "Cream Cheese": {
    "en": "Cream Cheese",
    "ja": "クリームチーズ",
  },
  "Rice Flour": {
    "en": "Rice Flour",
    "ja": "米粉",
  },
  "Cooked Rice": {
    "en": "Cooked Rice",
    "ja": "炊いた米",
  },
  "Floral": {
    "en": "Floral",
    "ja": "フローラル",
  },
  "Blossoms": {
    "en": "Blossoms",
    "ja": "花の香り",
  },
  "Rose": {
    "en": "Rose",
    "ja": "ローズ",
  },
  "Jasmine": {
    "en": "Jasmine",
    "ja": "ジャスミン",
  },
  "Fruity": {
    "en": "Fruity",
    "ja": "フルーティ",
  },
  "Melon": {
    "en": "Melon",
    "ja": "メロン",
  },
  "Lychee": {
    "en": "Lychee",
    "ja": "ライチ",
  },
  "Pear": {
    "en": "Pear",
    "ja": "洋梨",
  },
  "Green Apple": {
    "en": "Green Apple",
    "ja": "青りんご",
  },
  "Banana": {
    "en": "Banana",
    "ja": "バナナ",
  },
  "Berry": {
    "en": "Berry",
    "ja": "ベリー",
  },
  "Pineapple": {
    "en": "Pineapple",
    "ja": "パイナップル",
  },
  "Citrus": {
    "en": "Citrus",
    "ja": "柑橘",
  },
  "Herbaceous": {
    "en": "Herbaceous",
    "ja": "ハーブ",
  },
  "Grass": {
    "en": "Grass",
    "ja": "青草",
  },
  "Earthy": {
    "en": "Earthy",
    "ja": "土っぽい",
  },
  "Mushroom": {
    "en": "Mushroom",
    "ja": "キノコ",
  },
  "Oak": {
    "en": "Oak",
    "ja": "樽香",
  },
  "Vanilla": {
    "en": "Vanilla",
    "ja": "バニラ",
  },
  "Spicy": {
    "en": "Spicy",
    "ja": "スパイシー",
  },
  "Anise": {
    "en": "Anise",
    "ja": "アニス",
  },
  "Pepper": {
    "en": "Pepper",
    "ja": "胡椒",
  },
  "Cinnamon": {
    "en": "Cinnamon",
    "ja": "シナモン",
  },
  "Almond": {
    "en": "Almond",
    "ja": "アーモンド",
  },
  "Honey": {
    "en": "Honey",
    "ja": "ハチミツ",
  },
  "Chocolate": {
    "en": "Chocolate",
    "ja": "チョコレート",
  },
  "Red fruit": {
    "en": "Red fruit",
    "ja": "赤い果実",
  },
  "Black fruit": {
    "en": "Black fruit",
    "ja": "黒い果実",
  },
  "Tree fruit": {
    "en": "Tree fruit",
    "ja": "果樹系の香り",
  },
  "Citrus Fruit": {
    "en": "Citrus Fruit",
    "ja": "柑橘系",
  },
  "Tropical fruit": {
    "en": "Tropical fruit",
    "ja": "トロピカルフルーツ",
  },
  "Vegetal": {
    "en": "Vegetal",
    "ja": "野菜っぽい",
  },
  "Oaky": {
    "en": "Oaky",
    "ja": "樽香",
  },
  "Spices": {
    "en": "Spices",
    "ja": "スパイス",
  },
  "Ageing": {
    "en": "Ageing",
    "ja": "熟成感",
  },
  "Yeasty": {
    "en": "Yeasty",
    "ja": "酵母由来",
  },
  "Dried fruit": {
    "en": "Dried fruit",
    "ja": "ドライフルーツ",
  },
};
export const PARING_IMAGES: {
  [key: string]: string;
} = {
  "Sashimi": "/images/paring/sashimi.png",
  "Grilled Fish": "/images/paring/grilled-fish.png",
  "Tempura": "/images/paring/tempura.png",
  "Meat": "/images/paring/meat.png",
  "Cheese": "/images/paring/cheese.png",
  "Pickled Vegetables": "/images/paring/pickled-vegetables.png",
  "Fried Foods": "/images/paring/fried-foods.png",
  "Sushi": "/images/paring/sushi.png",
  "Tofu Dishes": "/images/paring/tofu-dishes.png",
  "Seafood": "/images/paring/seafood.png",
  "Vegetable Stir Fry": "/images/paring/vegetable-stir-fry.png",
  "Ramen": "/images/paring/ramen.png",
  "Barbecue": "/images/paring/barbecue.png",
  "Pasta": "/images/paring/pasta.png",
  "Pizza": "/images/paring/pizza.png",
  "Salads": "/images/paring/salads.png",
  "Soup": "/images/paring/soup.png",
  "Burgers": "/images/paring/burgers.png",
  "Sandwiches": "/images/paring/sandwiches.png",
  "Desserts": "/images/paring/desserts.png",
  "Chocolate Desserts": "/images/paring/desserts.png",
  "BBQ": "/images/paring/barbecue.png",
  "Mexican Food": "/images/paring/mexican-food.png",
  "Snacks": "/images/paring/snacks.png",
  "Chinese Food": "/images/paring/chinese-food.png",
  "Fish": "/images/paring/fish.png",
  "Shellfish": "/images/paring/shellfish.png",
}
export const PARING_TRANSLATION: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  "Sashimi": {
    "en": "Sashimi",
    "ja": "刺身",
  },
  "Grilled Fish": {
    "en": "Grilled Fish",
    "ja": "焼き魚",
  },
  "Tempura": {
    "en": "Tempura",
    "ja": "天ぷら",
  },
  "Meat": {
    "en": "Meat",
    "ja": "肉料理",
  },
  "Cheese": {
    "en": "Cheese",
    "ja": "チーズ",
  },
  "Pickled Vegetables": {
    "en": "Pickled Vegetables",
    "ja": "漬物",
  },
  "Fried Foods": {
    "en": "Fried Foods",
    "ja": "揚げ物",
  },
  "Sushi": {
    "en": "Sushi",
    "ja": "寿司",
  },
  "Tofu Dishes": {
    "en": "Tofu Dishes",
    "ja": "豆腐料理",
  },
  "Seafood": {
    "en": "Seafood",
    "ja": "海鮮料理",
  },
  "Vegetable Stir Fry": {
    "en": "Vegetable Stir Fry",
    "ja": "野菜炒め",
  },
  "Ramen": {
    "en": "Ramen",
    "ja": "ラーメン",
  },
  "Barbecue": {
    "en": "Barbecue",
    "ja": "バーベキュー",
  },
  "Pasta": {
    "en": "Pasta",
    "ja": "パスタ",
  },
  "Pizza": {
    "en": "Pizza",
    "ja": "ピザ",
  },
  "Salads": {
    "en": "Salads",
    "ja": "サラダ",
  },
  "Soup": {
    "en": "Soup",
    "ja": "スープ",
  },
  "Burgers": {
    "en": "Burgers",
    "ja": "ハンバーガー",
  },
  "Sandwiches": {
    "en": "Sandwiches",
    "ja": "サンドイッチ",
  },
  "Desserts": {
    "en": "Desserts",
    "ja": "デザート",
  },
  "Chocolate Desserts": {
    "en": "Chocolate Desserts",
    "ja": "チョコレートデザート",
  },
  "BBQ": {
    "en": "BBQ",
    "ja": "バーベキュー",
  },
  "Mexican Food": {
    "en": "Mexican Food",
    "ja": "メキシコ料理",
  },
  "Snacks": {
    "en": "Snacks",
    "ja": "おつまみ",
  },
  "Chinese Food": {
    "en": "Chinese Food",
    "ja": "中華料理",
  },
  "Fish": {
    "en": "Fish",
    "ja": "魚料理",
  },
  "Shellfish": {
    "en": "Shellfish",
    "ja": "貝料理",
  },
}
export const RICE_TRANSLATION: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  "Koshikagura, Koshiibuki": {
    en: "Koshikagura, Koshiibuki",
    ja: "越神楽・こしいぶき",
  },
  "Wataribune": {
    en: "Wataribune",
    ja: "渡船",
  },
  "Yamadanishiki": {
    en: "Yamadanishiki",
    ja: "山田錦",
  },
  "Hattannishiki": {
    en: "Hattannishiki",
    ja: "八反錦",
  },
  "Gohyakumangoku": {
    en: "Gohyakumangoku",
    ja: "五百万石",
  },
  "Miyamanishiki": {
    en: "Miyamanishiki",
    ja: "美山錦",
  },
  "Haenuki, Dewasansan, Yamadanishiki": {
    en: "Haenuki, Dewasansan, Yamadanishiki",
    ja: "はえぬき・出羽燦々・山田錦",
  },
  "MIyamanishiki, Kuranohana, Ginnoiroha": {
    en: "Miyamanishiki, Kuranohana, Ginnoiroha",
    ja: "美山錦・蔵の華・吟のいろは",
  },
  "Yuinoka": {
    en: "Yuinoka",
    ja: "結の香",
  },
  "Omachi": {
    en: "Omachi",
    ja: "雄町",
  },
  "Akitsuho": {
    en: "Akitsuho",
    ja: "秋津穂",
  },
  "Koshihikari": {
    en: "Koshihikari",
    ja: "コシヒカリ",
  },
  "Ginpu": {
    en: "Ginpu",
    ja: "吟風",
  },
  "Dewanosato": {
    en: "Dewanosato",
    ja: "出羽の里",
  },
  "Akita Sakekomachi": {
    en: "Akita Sakekomachi",
    ja: "秋田酒こまち",
  },
  "Dewasansan": {
    en: "Dewasansan",
    ja: "出羽燦々",
  },
  "Tamasakae, Ginfubuki": {
    en: "Tamasakae, Ginfubuki",
    ja: "玉栄・吟吹雪",
  },
  "西都の雫・山田錦": {
    en: "Saito no Shizuku, Yamada Nishiki",
    ja: "西都の雫・山田錦",
  },
  "Domaine Sakura Kamenoo": {
    en: "Domaine Sakura Kamenoo",
    ja: "ドメーヌさくら 亀の尾",
  },
  "Hitomebore": {
    en: "Hitomebore",
    ja: "ひとめぼれ",
  },
  "Koshinoshizuku": {
    en: "Koshinoshizuku",
    ja: "越の雫",
  },
  "Ipponjime, Koshitanrei, Gohyakumangoku": {
    en: "Ipponjime, Koshitanrei, Gohyakumangoku",
    ja: "一本締め・越淡麗・五百万石",
  },
  "Asahimai": {
    en: "Asahimai",
    ja: "旭米",
  },
  "Yumenokaori": {
    en: "Yumenokaori",
    ja: "夢の香",
  },
  "Aiyama": {
    en: "Aiyama",
    ja: "愛山",
  },
  "Miyamanishiki, Akita Sakekomachi": {
    en: "Miyamanishiki, Akita Sakekomachi",
    ja: "美山錦・秋田酒こまち",
  },
  "Yamadanishiki, Akitasake Komachi": {
    en: "Yamadanishiki, Akita Sakekomachi",
    ja: "山田錦・秋田酒こまち",
  },
  "Misatonishiki": {
    en: "Misatonishiki",
    ja: "美郷錦",
  },
  "Kitashizuku": {
    en: "Kitashizuku",
    ja: "きたしずく",
  },
  "Goriki": {
    en: "Goriki",
    ja: "強力",
  },
  "Yukinosei": {
    en: "Yukinosei",
    ja: "雪の精",
  },
  "Matsuyamamii": {
    en: "Matsuyamamii",
    ja: "松山三井",
  },
  "Yamadanishiki, Nihonbare": {
    en: "Yamadanishiki, Nihonbare",
    ja: "山田錦・日本晴",
  },
  "Yamadanishiki, Gohyakumangoku": {
    en: "Yamadanishiki, Gohyakumangoku",
    ja: "山田錦・五百万石",
  },
  "Matsuyamamitsui": {
    en: "Matsuyamamitsui",
    ja: "松山三井",
  },
  "山田錦": {
    en: "Yamadanishiki",
    ja: "山田錦",
  },
  "Natsuhikari, Yamadanishiki": {
    en: "Natsuhikari, Yamadanishiki",
    ja: "夏ひかり・山田錦",
  },
  "Yamadanishiki, Ginfubuki": {
    en: "Yamadanishiki, Ginfubuki",
    ja: "山田錦・吟吹雪",
  },
  "Akitasake Komachi, Hitomebore": {
    en: "Akitasake Komachi, Hitomebore",
    ja: "秋田酒こまち・ひとめぼれ",
  },
  "Nikomaru": {
    en: "Nikomaru",
    ja: "にこまる",
  },
  "Kamenoo": {
    en: "Kamenoo",
    ja: "亀の尾",
  },
  "Yamadanishiki, Miyamanishiki": {
    en: "Yamadanishiki, Miyamanishiki",
    ja: "山田錦・美山錦",
  },
  "Akita Sakekomachi, Hitomebore": {
    en: "Akita Sakekomachi, Hitomebore",
    ja: "秋田酒こまち・ひとめぼれ",
  },
  "Miyamanishiki, Gohyakumangoku": {
    en: "Miyamanishiki, Gohyakumangoku",
    ja: "美山錦・五百万石",
  },
  "Ginsan": {
    en: "Ginsan",
    ja: "ぎんさん",
  },
  "Yamadanishiki, Reiho": {
    en: "Yamadanishiki, Reiho",
    ja: "山田錦・麗峰",
  },
  "Gohyakumangoku, Kamenoo, Koshihikari, Koshitanrei": {
    en: "Gohyakumangoku, Kamenoo, Koshihikari, Koshitanrei",
    ja: "五百万石・亀の尾・コシヒカリ・越淡麗",
  },
  "Asahinoyume": {
    en: "Asahinoyume",
    ja: "あさひの夢",
  },
  "Akigeshiki": {
    en: "Akigeshiki",
    ja: "秋景色",
  },
  "Maikaze": {
    en: "Maikaze",
    ja: "舞風",
  },
  "Gohyakumangoku, Mochi rice": {
    en: "Gohyakumangoku, Mochi rice",
    ja: "五百万石・餅米",
  },
  "Yamadanishiki, Hattannishiki": {
    en: "Yamadanishiki, Hattannishiki",
    ja: "山田錦・八反錦",
  },
  "Miyamanishiki, Dewakirari": {
    en: "Miyamanishiki, Dewakirari",
    ja: "美山錦・出羽きらり",
  },
  "Gohyakumangoku, Yamadanishiki": {
    en: "Gohyakumangoku, Yamadanishiki",
    ja: "五百万石・山田錦",
  },
  "Yumesansui, Yumeginka": {
    en: "Yumesansui, Yumeginka",
    ja: "夢山水・夢吟香",
  },
  "Gohyakumangoku, Ancient rice": {
    en: "Gohyakumangoku, Ancient rice",
    ja: "五百万石・古代米",
  },
  "Kyono Kagayaki": {
    en: "Kyono Kagayaki",
    ja: "京のかがやき",
  },
  "Ooseto": {
    en: "Ooseto",
    ja: "大瀬戸",
  },
  "Yumesansui": {
    en: "Yumesansui",
    ja: "夢山水",
  },
  "Hanafubuki": {
    en: "Hanafubuki",
    ja: "華吹雪",
  },
  "Kuranohana": {
    en: "Kuranohana",
    ja: "蔵の華",
  },
  "Maihime": {
    en: "Maihime",
    ja: "舞姫",
  },
  "test": {
    en: "test",
    ja: "テスト",
  },
  "Aiyama, Yamadanishiki": {
    en: "Aiyama, Yamadanishiki",
    ja: "愛山・山田錦",
  },
  // NOTE: 合っているか怪しい
  "oma": {
    en: "oma",
    ja: "おま",
  },
  "Gohyakumangoku, Ginohmi": {
    en: "Gohyakumangoku, Ginohmi",
    ja: "五百万石・吟の海",
  },
  "Kairyo Shinko": {
    en: "Kairyo Shinko",
    ja: "改良信交",
  },
  "MIyamanishiki, Hitogokochi": {
    en: "Miyamanishiki, Hitogokochi",
    ja: "美山錦・ひとごこち",
  },
  "Calrose": {
    en: "Calrose",
    ja: "カルローズ",
  },
  "Dewasansan, Miyamanishiki, Gohyakumangoku": {
    en: "Dewasansan, Miyamanishiki, Gohyakumangoku",
    ja: "出羽燦々・美山錦・五百万石",
  },
  "Gohyakumangoku, Chiyonishiki": {
    en: "Gohyakumangoku, Chiyonishiki",
    ja: "五百万石・千代錦",
  },
  "Wakamizu": {
    en: "Wakamizu",
    ja: "若水",
  },
  "Aiyaa, Omachi": {
    en: "Aiyama, Omachi",
    ja: "愛山・雄町",
  },
  "Hidahomare": {
    en: "Hidahomare",
    ja: "飛騨ほまれ",
  },
  "Koshikagura": {
    en: "Koshikagura",
    ja: "越神楽",
  },
  "Hitogokochi": {
    en: "Hitogokochi",
    ja: "ひとごこち",
  },
  "Kamenoo, Yamadanishiki": {
    en: "Kamenoo, Yamadanishiki",
    ja: "亀の尾・山田錦",
  },
  "Yamadanishiki, Omachi": {
    en: "Yamadanishiki, Omachi",
    ja: "山田錦・雄町",
  },
  "Koshitanrei": {
    en: "Koshitanrei",
    ja: "越淡麗",
  },
  "Manamusume": {
    en: "Manamusume",
    ja: "まなむすめ",
  },
  "Yumenishiki, Yamadanishiki": {
    en: "Yumenishiki, Yamadanishiki",
    ja: "夢錦・山田錦",
  },
  "Nihonbare, Yamadanishiki": {
    en: "Nihonbare, Yamadanishiki",
    ja: "日本晴・山田錦",
  },
  "Koshiibuki": {
    en: "Koshiibuki",
    ja: "こしいぶき",
  },
  "Ginnoyume": {
    en: "Ginnoyume",
    ja: "吟の夢",
  },
  "Yukimegami": {
    en: "Yukimegami",
    ja: "雪女神",
  },
  "Koshitanrei, Koshiibuki": {
    en: "Koshitanrei, Koshiibuki",
    ja: "越淡麗・こしいぶき",
  },
  "Yamasake4": {
    en: "Yamasake4",
    ja: "山酒4号",
  },
  "Yamadanishiki, Yumeikkon": {
    en: "Yamadanishiki, Yumeikkon",
    ja: "山田錦・夢一献",
  },
  "Akihikari": {
    en: "Akihikari",
    ja: "あきひかり",
  },
  "Gohyakumangoku, Hanafubuki, Other": {
    en: "Gohyakumangoku, Hanafubuki, Other",
    ja: "五百万石・華吹雪・その他",
  },
  "Sasashigure": {
    en: "Sasashigure",
    ja: "ささしぐれ",
  },
  "Hanafubuki, Gohyakumangoku": {
    en: "Hanafubuki, Gohyakumangoku",
    ja: "華吹雪・五百万石",
  },
  "Haenuki": {
    en: "Haenuki",
    ja: "はえぬき",
  },
  "Calhikari": {
    en: "Calhikari",
    ja: "カルヒカリ",
  },
  "Iwai": {
    en: "Iwai",
    ja: "祝（いわい）",
  },
  "Yamadanishiki, Kinmonnishiki": {
    en: "Yamadanishiki, Kinmonnishiki",
    ja: "山田錦・金紋錦",
  },
  "Yamadanishiki, Omachi, Gohyakumangoku": {
    en: "Yamadanishiki, Omachi, Gohyakumangoku",
    ja: "山田錦・雄町・五百万石",
  },
  "日本晴": {
    en: "Nihonbare",
    ja: "日本晴",
  },
  "Sakemusashi": {
    en: "Sakemusashi",
    ja: "さけ武蔵",
  },
  "Nakate Shinsenbon": {
    en: "Nakate Shinsenbon",
    ja: "中生新千本",
  },
  "MIyamanishiki, Fusakogane": {
    en: "Miyamanishiki, Fusakogane",
    ja: "美山錦・扶桑黄金",
  },
  "Saganohana": {
    en: "Saganohana",
    ja: "さがの華",
  },
  "Akebono": {
    en: "Akebono",
    ja: "あけぼの",
  },
  "Miyamanishiki, Yukigesho": {
    en: "Miyamanishiki, Yukigesho",
    ja: "美山錦・雪化粧",
  },
  "Nihonbare": {
    "en": "Nihonbare",
    "ja": "日本晴",
  },
  "Hyogo Yumenishiki": {
    en: "Hyogo Yumenishiki",
    ja: "兵庫夢錦",
  },
  "Fukunoka": {
    en: "Fukunoka",
    ja: "福の香",
  },
  "Menkoina, Red Rice": {
    en: "Menkoina, Red Rice",
    ja: "めんこいな・赤米",
  },
  "Homarefuji": {
    en: "Homarefuji",
    ja: "誉富士",
  },
  "Ippanmai": {
    en: "Ippanmai",
    ja: "一般米",
  },
  "Hattannishiki, Nakate Shinsenbon": {
    en: "Hattannishiki, Nakate Shinsenbon",
    ja: "八反錦・中生新千本",
  },
  "Hidaminori": {
    en: "Hidaminori",
    ja: "飛騨みのり",
  },
  "Yumesansui, Chiyonishiki": {
    en: "Yumesansui, Chiyonishiki",
    ja: "夢山水・千代錦",
  },
  "Ipponjime": {
    en: "Ipponjime",
    ja: "一本締め",
  },
  "Wataribune No.2": {
    en: "Wataribune No.2",
    ja: "渡船2号",
  },
  "Oyamanishiki": {
    en: "Oyamanishiki",
    ja: "雄山錦",
  },
  "Notohikari": {
    en: "Notohikari",
    ja: "能登ひかり",
  },
  "Gohyakumangoku, Hitogokochi": {
    en: "Gohyakumangoku, Hitogokochi",
    ja: "五百万石・ひとごこち",
  },
  "Akitakomachi": {
    en: "Akitakomachi",
    ja: "あきたこまち",
  },
  "Gohyakumangoku, Fusakogane": {
    en: "Gohyakumangoku, Fusakogane",
    ja: "五百万石・扶桑黄金",
  },
  "Gohyakumangoku, Red rice": {
    en: "Gohyakumangoku, Red rice",
    ja: "五百万石・赤米",
  },
  "Tamasakae": {
    en: "Tamasakae",
    ja: "玉栄",
  },
  "Kuranohana, Miyamanishiki": {
    en: "Kuranohana, Miyamanishiki",
    ja: "蔵の華・美山錦",
  },
  "Ginotome": {
    en: "Ginotome",
    ja: "ぎんおとめ",
  },
  "Tsuyuhakaze": {
    en: "Tsuyuhakaze",
    ja: "露葉風",
  },
  "Yumesasara": {
    en: "Yumesasara",
    ja: "夢ささら",
  },
  "Yamadanishiki, Aiyama": {
    en: "Yamadanishiki, Aiyama",
    ja: "山田錦・愛山",
  },
  "Ginfubuki": {
    en: "Ginfubuki",
    ja: "吟吹雪",
  },
  "Miyama": {
    en: "Miyamanishiki",
    ja: "美山錦",
  },
  "Sakemirai": {
    en: "Sakemirai",
    ja: "酒未来",
  },
  "日本晴・山田錦": {
    en: "Nihonbare, Yamadanishiki",
    ja: "日本晴・山田錦",
  },
  "Ginotome, Yamadanishiki": {
    en: "Ginotome, Yamadanishiki",
    ja: "ぎんおとめ・山田錦",
  },
  "Chiyonishiki": {
    en: "Chiyonishiki",
    ja: "千代錦",
  },
  "Kitanishiki": {
    en: "Kitanishiki",
    ja: "北錦",
  },
  "Yamadanishiki, Haenuki": {
    en: "Yamadanishiki, Haenuki",
    ja: "山田錦・はえぬき",
  },
  "Reihou": {
    en: "Reihou",
    ja: "麗峰",
  },
  "Yukihotaka": {
    en: "Yukihotaka",
    ja: "雪ほたか",
  },
  "Toyonishiki": {
    en: "Toyonishiki",
    ja: "豊錦",
  },
  "Gohyakumangoku, Koshitanrei": {
    en: "Gohyakumangoku, Koshitanrei",
    ja: "五百万石・越淡麗",
  },
  "Tankan Wataribune": {
    en: "Tankan Wataribune",
    ja: "短稈渡船",
  },
  "Yukiwakamaru": {
    en: "Yukiwakamaru",
    ja: "雪若丸",
  },
  "Miyamanishiki, Yamadanishiki": {
    en: "Miyamanishiki, Yamadanishiki",
    ja: "美山錦・山田錦",
  },
  "Hattannishiki, Shinsenbon": {
    en: "Hattannishiki, Shinsenbon",
    ja: "八反錦・新千本",
  },
  "Akitakada, Hiroshima Hattanso": {
    en: "Akitakada, Hiroshima Hattanso",
    ja: "安芸高田・広島八反草",
  },
  "Omachi, Hattannishiki": {
    en: "Omachi, Hattannishiki",
    ja: "雄町・八反錦",
  },
  "Gohyakumangoku, Miyamanishiki": {
    en: "Gohyakumangoku, Miyamanishiki",
    ja: "五百万石・美山錦",
  },
  "Yamadaho": {
    en: "Yamadaho",
    ja: "山田穂",
  },
  "Hitachinishiki": {
    en: "Hitachinishiki",
    ja: "常陸錦",
  },
  "Kikusui": {
    en: "Kikusui",
    ja: "菊水",
  },
  "Shizukuhime": {
    en: "Shizukuhime",
    ja: "雫姫",
  },
  "Hinohikari": {
    en: "Hinohikari",
    ja: "ヒノヒカリ",
  },
  "Yamadanishiki, Miyamanishiki, Gohyakumangoku": {
    en: "Yamadanishiki, Miyamanishiki, Gohyakumangoku",
    ja: "山田錦・美山錦・五百万石",
  },
  "Wataribune No.6": {
    en: "Wataribune No.6",
    ja: "渡船6号",
  },
  "Kokuho rose": {
    en: "Kokuho rose",
    ja: "コクホーローズ",
  },
  "Ginginga": {
    en: "Ginginga",
    ja: "吟ぎんが",
  },
  "Yamadanishiki, Koshitanrei": {
    en: "Yamadanishiki, Koshitanrei",
    ja: "山田錦・越淡麗",
  },
  "Koimomiji, Hattannishiki": {
    en: "Koimomiji, Hattannishiki",
    ja: "恋もみじ・八反錦",
  },
  "Fusanomai": {
    en: "Fusanomai",
    ja: "ふさの舞",
  },
  "Kannomai": {
    en: "Kannomai",
    ja: "神の舞",
  },
  "Hitogokochi, Miyamanishiki": {
    en: "Hitogokochi, Miyamanishiki",
    ja: "ひとごこち・美山錦",
  },
  "Yamadanishiki, Hitogokochi": {
    en: "Yamadanishiki, Hitogokochi",
    ja: "山田錦・ひとごこち",
  },
  "tennotsubu": {
    en: "tennotsubu",
    ja: "天のつぶ",
  },
  "Soubewase": {
    en: "Soubewase",
    ja: "惣兵衛早生（そうべわせ）",
  },
  "Ginotome, Miyamanishiki": {
    en: "Ginotome, Miyamanishiki",
    ja: "ぎんおとめ・美山錦",
  },
  "Sasanishiki": {
    en: "Sasanishiki",
    ja: "ササニシキ",
  },
  "Ishikawamon": {
    en: "Ishikawamon",
    ja: "石川門",
  },
  "Gohyakumangoku, Tentakaku": {
    en: "Gohyakumangoku, Tentakaku",
    ja: "五百万石・てんたかく",
  },
  "Sakemusashi, Gohyakumangoku": {
    en: "Sakemusashi, Gohyakumangoku",
    ja: "さけ武蔵・五百万石",
  },
  "Rakufumai": {
    en: "Rakufumai",
    ja: "楽風舞",
  },
  "Luna Koshihikari": {
    en: "Luna Koshihikari",
    ja: "ルナコシヒカリ",
  },
  "Ginnosei": {
    en: "Ginnosei",
    ja: "吟の精",
  },
  "Toyonishiki, Kuranohana, Ginnoiroha": {
    en: "Toyonishiki, Kuranohana, Ginnoiroha",
    ja: "豊錦・蔵の華・吟のいろは",
  },
  "Yamadanishiki, Tamasakae": {
    en: "Yamadanishiki, Tamasakae",
    ja: "山田錦・玉栄",
  },
  "Haenuki, Miyamanishiki": {
    en: "Haenuki, Miyamanishiki",
    ja: "はえぬき・美山錦",
  },
  "Yamadanishiki, Yumenishiki": {
    en: "Yamadanishiki, Yumenishiki",
    ja: "山田錦・夢錦",
  },
  "Gohyakumangoku, Koshiibuki": {
    en: "Gohyakumangoku, Koshiibuki",
    ja: "五百万石・こしいぶき",
  },
  "Dewasansan, Miyamanishiki": {
    en: "Dewasansan, Miyamanishiki",
    ja: "出羽燦々・美山錦",
  },
  "Harumi": {
    en: "Harumi",
    ja: "はるみ",
  },
  "Calihikari": {
    en: "Calihikari",
    ja: "カリヒカリ",
  },
  "Senbonnishiki": {
    en: "Senbonnishiki",
    ja: "千本錦",
  },
  "Fusakogane": {
    en: "Fusakogane",
    ja: "扶桑黄金",
  },
  "Aiyama, Ipponjime": {
    en: "Aiyama, Ipponjime",
    ja: "愛山・一本締め",
  },
  "Takashima Omachi": {
    en: "Takashima Omachi",
    ja: "高島雄町",
  },
  "Koshitanrei, Yamadanishiki, Miyamanishiki": {
    en: "Koshitanrei, Yamadanishiki, Miyamanishiki",
    ja: "越淡麗・山田錦・美山錦",
  },
  "Isehikari, Yamadanishiki": {
    en: "Isehikari, Yamadanishiki",
    ja: "伊勢ひかり・山田錦",
  },
  "Tamanae": {
    en: "Tamanae",
    ja: "玉苗",
  },
  "Yamadanishiki, Akebono": {
    en: "Yamadanishiki, Akebono",
    ja: "山田錦・あけぼの",
  },
  "Rikuu132": {
    en: "Rikuu132",
    ja: "陸羽132号",
  },
  "Kinuhikari": {
    en: "Kinuhikari",
    ja: "きぬひかり",
  },
  "Tamazakae": {
    en: "Tamasakae",
    ja: "玉栄",
  },
  "Fusakogane, Yamadanishiki": {
    en: "Fusakogane, Yamadanishiki",
    ja: "扶桑黄金・山田錦",
  },
  "Hakutsurunishsiki": {
    en: "Hakutsurunishiki",
    ja: "白鶴錦",
  },
  "Yamadanishiki, Chiyonishiki": {
    en: "Yamadanishiki, Chiyonishiki",
    ja: "山田錦・千代錦",
  },
  "Shinriki": {
    en: "Shinriki",
    ja: "神力",
  },
  "Gohyakumangoku, Wasejiman": {
    en: "Gohyakumangoku, Wasejiman",
    ja: "五百万石・早生（わせ）じまん",
  },
  "Mienoyume": {
    en: "Mienoyume",
    ja: "三重の夢",
  },
  "Gohyakumangoku, Koishiibuki": {
    en: "Gohyakumangoku, Koshiibuki",
    ja: "五百万石・こしいぶき",
  },
  "Kinmonnishiki": {
    en: "Kinmonnishiki",
    ja: "金紋錦",
  },
  "Yamadanishiki, Gohyakumangoku, Fukunoka": {
    en: "Yamadanishiki, Gohyakumangoku, Fukunoka",
    ja: "山田錦・五百万石・福の香",
  },
  "Menkoina": {
    en: "Menkoina",
    ja: "めんこいな",
  },
  "Sagabiyori": {
    en: "Sagabiyori",
    ja: "佐賀日和",
  },
  "Kobe yumenishiki": {
    en: "Kobe Yumenishiki",
    ja: "神戸夢錦",
  },
  "Mizuhono Kagayaki": {
    en: "Mizuhono Kagayaki",
    ja: "みずほの輝き",
  },
  "Tochigisake 14": {
    en: "Tochigisake 14",
    ja: "栃木酒14",
  },
  // NOTE: 合っているか怪しい
  "Homase": {
    en: "Homase",
    ja: "ほま瀬",
  },
  "Akebono, Yamadanishiki": {
    en: "Akebono, Yamadanishiki",
    ja: "あけぼの・山田錦",
  },
  "Yamadanishiki, Gohyakumangoku, Omachi": {
    en: "Yamadanishiki, Gohyakumangoku, Omachi",
    ja: "山田錦・五百万石・雄町",
  },
  "Omachi, Yamadanishiki": {
    en: "Omachi, Yamadanishiki",
    ja: "雄町・山田錦",
  },
  "日本晴・山田錦・美山錦": {
    en: "Nihonbare, Yamadanishiki, Miyamanishiki",
    ja: "日本晴・山田錦・美山錦",
  },
  "Yamadanishiki, Calrose": {
    en: "Yamadanishiki, Calrose",
    ja: "山田錦・カルローズ",
  },
  "Ginohmi, Gohyakumangoku": {
    en: "Ginohmi, Gohyakumangoku",
    ja: "吟の海・五百万石",
  },
  "Domaine Sakura Yamadanishiki": {
    en: "Domaine Sakura Yamadanishiki",
    ja: "ドメーヌさくら 山田錦",
  },
  "Isehikari": {
    en: "Isehikari",
    ja: "伊勢ひかり",
  },
  "Ginnosei, Menkoina, Ginsan": {
    en: "Ginnosei, Menkoina, Ginsan",
    ja: "吟の精・めんこいな・ぎんさん",
  },
  "Ginoumi Koshihikari": {
    en: "Ginoumi Koshihikari",
    ja: "吟の海 コシヒカリ",
  },
  "Suisei": {
    en: "Suisei",
    ja: "彗星",
  },
  "Yamadanishiki, Saganohana": {
    en: "Yamadanishiki, Saganohana",
    ja: "山田錦・さがの華",
  },
  "Koshitanrei, Koshi Ibuki": {
    en: "Koshitanrei, Koshiibuki",
    ja: "越淡麗・こしいぶき",
  },
  "Gohyakumangoku, Hanafubuki": {
    en: "Gohyakumangoku, Hanafubuki",
    ja: "五百万石・華吹雪",
  },
  "Happy Hill": {
    en: "Happy Hill",
    ja: "ハッピーヒル",
  },
  "Masshigura": {
    en: "Masshigura",
    ja: "まっしぐら",
  },
  "Gohyakumangoku, Nihonbare": {
    en: "Gohyakumangoku, Nihonbare",
    ja: "五百万石・日本晴",
  },
  "Houhaimai": {
    en: "Houhaimai",
    ja: "豊盃米",
  },
  "Yamadanishiki, Akitakomachi": {
    en: "Yamadanishiki, Akitakomachi",
    ja: "山田錦・あきたこまち",
  },
  "Gin-eboshi": {
    en: "Gin-eboshi",
    ja: "銀烏帽子",
  },
  "Ginnosato": {
    en: "Ginnosato",
    ja: "吟の里",
  },
  "Sankeinishiki": {
    en: "Sankeinishiki",
    ja: "三恵錦",
  },
  "Yamadanishiki, Gohyakumangoku,etc": {
    en: "Yamadanishiki, Gohyakumangoku,etc",
    ja: "山田錦・五百万石・他",
  },
  "Jugemu": {
    en: "Jugemu",
    ja: "壽限無（じゅげむ）",
  },
  "Yamadanishiki, Hanaechizen": {
    en: "Yamadanishiki, Hanaechizen",
    ja: "山田錦・華越前",
  },
  "Yamadanishiki, Gohyakumangoku, Koshiibuki": {
    en: "Yamadanishiki, Gohyakumangoku, Koshiibuki",
    ja: "山田錦・五百万石・こしいぶき",
  },
  "Ippon-jime": {
    en: "Ippon-jime",
    ja: "一本締め",
  },
  "Calrose, Yamadanishiki": {
    en: "Calrose, Yamadanishiki",
    ja: "カルローズ・山田錦",
  },
  "Sakahomare": {
    en: "Sakahomare",
    ja: "さかほまれ",
  },
  "Hazukiminori": {
    en: "Hazukiminori",
    ja: "葉月みのり",
  },
  "Yamadanishiki, Yumeshizuku": {
    en: "Yamadanishiki, Yumeshizuku",
    ja: "山田錦・夢しずく",
  },
  "Asanohikari": {
    en: "Asanohikari",
    ja: "朝の光",
  },
  "Hatsushimo": {
    en: "Hatsushimo",
    ja: "初霜",
  },
  "Saganohana, Black rice": {
    en: "Saganohana, Black rice",
    ja: "さがの華・黒米",
  },
  "Takanenishiki": {
    en: "Takanenishiki",
    ja: "高嶺錦",
  },
  "Kaminoho": {
    en: "Kaminoho",
    ja: "神の穂",
  },
  "Dewakirari": {
    en: "Dewakirari",
    ja: "出羽きらり",
  },
  "Hyogo Sake 85": {
    en: "Hyogo Sake 85",
    ja: "兵庫酒85号",
  },
  "Camargue rice": {
    en: "Camargue rice",
    ja: "カマルグ米",
  },
  "Yamadanishiki, Kinmonnishiki, Ishikawamon, Fukunohana": {
    en: "Yamadanishiki, Kinmonnishiki, Ishikawamon, Fukunohana",
    ja: "山田錦・金紋錦・石川門・福の華",
  },
  "Hanaomoi": {
    en: "Hanaomoi",
    ja: "華想い",
  },
  "Yukigesho": {
    en: "Yukigesho",
    ja: "雪化粧",
  },
  "Yumeikkon": {
    en: "Yumeikkon",
    ja: "夢一献",
  },
  "Yumenishiki": {
    en: "Yumenishiki",
    ja: "夢錦",
  },
  "Tamasakae, Yamadanishiki": {
    en: "Tamasakae, Yamadanishiki",
    ja: "玉栄・山田錦",
  },
  "Senshuraku": {
    en: "Senshuraku",
    ja: "千秋楽",
  },
  "Gohyakumangoku, Yumenokaori, Kamenoo": {
    en: "Gohyakumangoku, Yumenokaori, Kamenoo",
    ja: "五百万石・夢の香・亀の尾",
  },
  "Tsuyahime": {
    en: "Tsuyahime",
    ja: "つや姫",
  },
  "Hoshiakari": {
    en: "Hoshiakari",
    ja: "星あかり",
  },
  "Gohyakumangoku, Asahinoyume": {
    en: "Gohyakumangoku, Asahinoyume",
    ja: "五百万石・あさひの夢",
  },
  "Hananishiki": {
    en: "Hananishiki",
    ja: "華錦",
  },
  "Morinokumasan": {
    en: "Morinokumasan",
    ja: "森のくまさん",
  },
  "Gohyakumangoku, Hinohikari": {
    en: "Gohyakumangoku, Hinohikari",
    ja: "五百万石・ヒノヒカリ",
  },
  "Hyakuden": {
    en: "Hyakuden",
    ja: "百田",
  },
  "Yamadanishiki, Hananishiki": {
    en: "Yamadanishiki, Hananishiki",
    ja: "山田錦・華錦",
  },
  "Akita Sakekomachi, Miyamanishiki, Akinosei": {
    en: "Akita Sakekomachi, Miyamanishiki, Akinosei",
    ja: "秋田酒こまち・美山錦・秋の精",
  },
  "Tokujo Omachi": {
    en: "Tokujo Omachi",
    ja: "特醸雄町",
  },
  "Miyamanishiki, Koshihikari": {
    en: "Miyamanishiki, Koshihikari",
    ja: "美山錦・コシヒカリ",
  },
  "Hiroshima Hattan, Shinsenbon": {
    en: "Hiroshima Hattan, Shinsenbon",
    ja: "広島八反・新千本",
  },
  "Yamadanishiki, Gohyakumangoku, Hattannishiki": {
    en: "Yamadanishiki, Gohyakumangoku, Hattannishiki",
    ja: "山田錦・五百万石・八反錦",
  },
  "Hiroshima Hattan, Hattannishiki": {
    en: "Hiroshima Hattan, Hattannishiki",
    ja: "広島八反・八反錦",
  },
  "Gohyakumangoku, Koshinoshizuku": {
    en: "Gohyakumangoku, Koshinoshizuku",
    ja: "五百万石・越の雫",
  },
  "Aiyama, Ancient Rice": {
    en: "Aiyama, Ancient Rice",
    ja: "愛山・古代米",
  },
  "Saganohana, Yamadanishiki, Oyamanishiki": {
    en: "Saganohana, Yamadanishiki, Oyamanishiki",
    ja: "さがの華・山田錦・雄山錦",
  },
  "Miyamanishiki, Yumeobako": {
    en: "Miyamanishiki, Yumeobako",
    ja: "美山錦・夢おばこ",
  },
  "Iwate Rice": {
    en: "Iwate Rice",
    ja: "岩手米",
  },
  "Yumenokaori, Koshihikari": {
    en: "Yumenokaori, Koshihikari",
    ja: "夢の香・コシヒカリ",
  },
  "Shunyo": {
    en: "Shunyo",
    ja: "春陽",
  },
  "Yamadanishiki, Saitono Shizuku": {
    en: "Yamadanishiki, Saitono Shizuku",
    ja: "山田錦・西都の雫",
  },
  "Mutsuhomar": {
    en: "Mutsuhomar",
    ja: "むつほまれ",
  },
};

export const SAKE_TYPE_TRANSLATION: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  "Honjozo": {
    en: "Honjozo",
    ja: "本醸造",
  },
  "Junmai Ginjo": {
    en: "Junmai Ginjo",
    ja: "純米吟醸",
  },
  "Junmai": {
    en: "Junmai",
    ja: "純米酒",
  },
  "Futsu Shu": {
    en: "Futsu Shu",
    ja: "普通酒",
  },
  "Junmai Daiginjo": {
    en: "Junmai Daiginjo",
    ja: "純米大吟醸",
  },
  "Tokubetsu Honjozo": {
    en: "Tokubetsu Honjozo",
    ja: "特別本醸造",
  },
  "Daiginjo": {
    en: "Daiginjo",
    ja: "大吟醸",
  },
  "Ginjo": {
    en: "Ginjo",
    ja: "吟醸",
  },
  "Umeshu": {
    en: "Umeshu",
    ja: "梅酒",
  },
  "Tokubetsu Junmai": {
    en: "Tokubetsu Junmai",
    ja: "特別純米",
  },
  "Nigori": {
    en: "Nigori",
    ja: "にごり酒",
  },
  "Kijo shu": {
    en: "Kijo shu",
    ja: "貴醸酒",
  },
  "Sparkling": {
    en: "Sparkling",
    ja: "スパークリング",
  },
  "Junmai Ginjo Nigori": {
    en: "Junmai Ginjo Nigori",
    ja: "純米吟醸にごり酒",
  },
  "Junmai Ginjo Genshu": {
    en: "Junmai Ginjo Genshu",
    ja: "純米吟醸原酒",
  },
  "Unknown Type": {
    en: "Unknown Type",
    ja: "種類不明",
  },
  "Japanese Sake": {
    en: "Japanese Sake",
    ja: "日本酒",
  },
  "liqueur": {
    en: "liqueur",
    ja: "リキュール",
  },
  "Doburoku": {
    en: "Doburoku",
    ja: "どぶろく",
  },
  "Junmai ginjo Nigori": {
    en: "Junmai ginjo Nigori",
    ja: "純米吟醸にごり酒",
  },
  "Junmai Nigori": {
    en: "Junmai Nigori",
    ja: "純米にごり酒",
  },
  "Futsu-shu": {
    en: "Futsu-shu",
    ja: "普通酒",
  },
  "Futsu Shu Nigori": {
    en: "Futsu Shu Nigori",
    ja: "普通酒にごり",
  },
  "test": {
    en: "test",
    ja: "テスト",
  },
  "Muroka Nama Genshu": {
    en: "Muroka Nama Genshu",
    ja: "無濾過生原酒",
  },
  "Tokubetsu Junmai Nigori": {
    en: "Tokubetsu Junmai Nigori",
    ja: "特別純米にごり酒",
  },
  "Junmai Daiginjo Nigori": {
    en: "Junmai Daiginjo Nigori",
    ja: "純米大吟醸にごり酒",
  },
  "Junmai Ginjo Nama": {
    en: "Junmai Ginjo Nama",
    ja: "純米吟醸生酒",
  },
  "Honjozo Genshu": {
    en: "Honjozo Genshu",
    ja: "本醸造原酒",
  },
  "Honjozo Nama Genshu": {
    en: "Honjozo Nama Genshu",
    ja: "本醸造生原酒",
  },
  "Junmai Sparkling": {
    en: "Junmai Sparkling",
    ja: "純米スパークリング",
  },
  "Ginjo Nama Genshu": {
    en: "Ginjo Nama Genshu",
    ja: "吟醸生原酒",
  },
  "Honjozo Nigori": {
    en: "Honjozo Nigori",
    ja: "本醸造にごり酒",
  },
  "Yamahai Junmai Ginjo": {
    en: "Yamahai Junmai Ginjo",
    ja: "山廃純米吟醸",
  },
  "Yamahai Junmai": {
    en: "Yamahai Junmai",
    ja: "山廃純米",
  },
  "Daiginjo Shizuku": {
    en: "Daiginjo Shizuku",
    ja: "大吟醸雫酒",
  },
  "Junmai Ginjo Muroka Nama Genshu": {
    en: "Junmai Ginjo Muroka Nama Genshu",
    ja: "純米吟醸無濾過生原酒",
  },
  "Cooking Sake": {
    en: "Cooking Sake",
    ja: "料理酒",
  },
  "Ginjo Nigori": {
    en: "Ginjo Nigori",
    ja: "吟醸にごり酒",
  },
  "Muroka Junmai Ginjo Nama Genshu": {
    en: "Muroka Junmai Ginjo Nama Genshu",
    ja: "無濾過純米吟醸生原酒",
  },
  "Daiginjo Nama": {
    en: "Daiginjo Nama",
    ja: "大吟醸生酒",
  },
  "Yuzu Sake": {
    en: "Yuzu Sake",
    ja: "ゆず酒",
  },
  "Futsu shu": {
    en: "Futsu shu",
    ja: "普通酒",
  },
  "Blend": {
    en: "Blend",
    ja: "ブレンド",
  },
  "Junmai Yuzu": {
    en: "Junmai Yuzu",
    ja: "純米ゆず酒",
  },
  "Kimoto Junmai": {
    en: "Kimoto Junmai",
    ja: "生酛純米",
  },
  "Unique": {
    en: "Unique",
    ja: "ユニーク",
  },
  "Junmai Muroka Nama Genshu": {
    en: "Junmai Muroka Nama Genshu",
    ja: "純米無濾過生原酒",
  },
  "Tokubetsu Honjozo Nama": {
    en: "Tokubetsu Honjozo Nama",
    ja: "特別本醸造生酒",
  },
  "Kijoshu": {
    en: "Kijoshu",
    ja: "貴醸酒",
  },
  "Aged Junmai Ginjo Nama": {
    en: "Aged Junmai Ginjo Nama",
    ja: "熟成純米吟醸生酒",
  },
  "Futsushu": {
    en: "Futsushu",
    ja: "普通酒",
  },
  "Junmai Koshu": {
    en: "Junmai Koshu",
    ja: "純米古酒",
  },
  "Other": {
    en: "Other",
    ja: "その他",
  },
  "Hatsuga Genmai Shu": {
    en: "Hatsuga Genmai Shu",
    ja: "発芽玄米酒",
  },
  "Nama Genshu": {
    en: "Nama Genshu",
    ja: "生原酒",
  },
  "Junmai Ginjo Nama Genshu": {
    en: "Junmai Ginjo Nama Genshu",
    ja: "純米吟醸生原酒",
  },
  "Junmai Kimoto": {
    en: "Junmai Kimoto",
    ja: "純米生酛",
  },
  "Junmai Daiginjo Fukurotsuri": {
    en: "Junmai Daiginjo Fukurotsuri",
    ja: "純米大吟醸袋吊り",
  },
  "Sparkling Nigori": {
    en: "Sparkling Nigori",
    ja: "スパークリングにごり酒",
  },
  "Junmai Muroka Genshu": {
    en: "Junmai Muroka Genshu",
    ja: "純米無濾過原酒",
  },
  "nigori": {
    en: "nigori",
    ja: "にごり酒",
  },
  "Nigori Junmai": {
    en: "Nigori Junmai",
    ja: "にごり純米",
  },
  "Usu Nigori Junmai": {
    en: "Usu Nigori Junmai",
    ja: "うすにごり純米",
  },
  "Koshu": {
    en: "Koshu",
    ja: "古酒",
  },
  "Ginjo Namachozo Ca": {
    en: "Ginjo Namachozo",
    ja: "吟醸生貯蔵酒",
  },
};

export const BusinessCard: {
  [key: string]: {
    name: string;
    sub: string;
    description: string;
    image: string;
    email?: string;
    twitter?: string;
    linkedin?: string;
    instagram?: string;
    newsletter?: string;
    facebook?: string;
  };
} = {
  "kenta": {
    "name": "Kenta Hara",
    "sub": "Co-Founder & CEO",
    "description": "I'm a software engineer with 6 years of solid experience. Nowadays, my primary focus has been building a high-performance web application using React. However, I also have experience in backend development and managing team. I'm an outstanding individual contributor and passionate about building a revolutionary product that improves the quality of life through teamwork.",
    "image": "/images/business-card/kenta.avif",
    "email": "kenta@hellosake.com",
    "twitter": "https://twitter.com/hellokenta_ja",
    "linkedin": "https://www.linkedin.com/in/kenta-hara-18003797/",
    "instagram": "https://www.instagram.com/kenta_loves_sake/",
    "newsletter": "https://hellosake.beehiiv.com/",
    "facebook": "https://www.facebook.com/kenta.hara.142",
  },
  "ryosuke": {
    "name": "Ryosuke Kuga",
    "sub": "Co-Founder & CSO",
    "description": "A connoisseur with three refrigerators at home, exclusively for storing his collection of 160 different sakes, he has cultivated extensive relationships with breweries and liquor stores throughout Japan. At HelloSake, he leverages these relationships along with his deep knowledge of sake to lead strategies and business development, aiming to enhance the appreciation and accessibility of this traditional Japanese beverage worldwide.",
    "image": "/images/business-card/ryosuke.avif",
    "email": "ryosuke@hellosake.com",
    "facebook": "https://www.facebook.com/ryosuke.kuga.9",
  },
  "daiaoki": {
    "name": "Dai Aoki",
    "sub": "Co-Founder & CTO",
    "description": "I'm a software engineer. I love sake, my dogs, and my wife. Bringing the goodness of Japan to the world through technology and passion.",
    "image": "/images/business-card/daiaoki.jpg",
    "email": "daiaoki@hellosake.com",
    "twitter": "https://twitter.com/DaiAoki30",
    "facebook": "https://www.facebook.com/dai.aoki.94",
  },
}

export const PUBLIC_WRITERS = [
  {
    id: "1",
    name: "Dai Aoki",
    image: "/images/business-card/daiaoki.jpg",
  },
  {
    id: "2",
    name: "Ryosuke Kuga",
    image: "/images/business-card/ryosuke.avif",
  },
  {
    id: "3",
    name: "Kenta Hara",
    image: "/images/business-card/kenta.avif",
  },
  {
    id: "4",
    name: "Chizuru Fukui",
    image: "/images/business-card/chizuru.jpeg",
  },
  {
    id: "9999",
    name: "Guest",
    image: "/images/no-image-user.png",
  },
];

export const LICENSES = [
  "SSA (Certified)",
  "SSA (Advanced)",
  "SSA (Master)",
  "WSET 1",
  "WSET 3",
  "Sake Diploma",
  "SSI (Kikisake-shi)",
  "SSI (Navigator)",
  "SSI (Master)"
];

export const SHIP_TO_COUNTRIES: {
  [key: string]: {
    name: {
      [key: string]: string;
    };
    icon?: string;
    comingSoon?: boolean;
  };
} = {
  "US": {
    "name": {
      "en": "United States",
      "ja": "アメリカ",
    },
    "icon": "/images/flag/us.svg",
  },
  "JP": {
    "name": {
      "en": "Japan",
      "ja": "日本",
    },
    "icon": "/images/flag/jp.svg",
    "comingSoon": true,
  },
  "OTHERS": {
    "name": {
      "en": "Others",
      "ja": "その他",
    },
  },
};

export const STATES: {
  [key: string]: {
    [key: string]: string;
  }
} = {
  "AL": {
    "en": "Alabama",
    "ja": "アラバマ",
  },
  "AK": {
    "en": "Alaska",
    "ja": "アラスカ",
  },
  "AS": {
    "en": "American Samoa",
    "ja": "アメリカ領サモア",
  },
  "AX": {
    "en": "Arizona",
    "ja": "アリゾナ",
  },
  "AR": {
    "en": "Arkansas",
    "ja": "アーカンソー",
  },
  "AA": {
    "en": "Armed Forces Americas",
    "ja": "米軍アメリカ地域",
  },
  "AE": {
    "en": "Armed Forces Europe",
    "ja": "米軍ヨーロッパ地域",
  },
  "AP": {
    "en": "Armed Forces Pacific",
    "ja": "米軍太平洋地域",
  },
  "CA": {
    "en": "California",
    "ja": "カリフォルニア",
  },
  "CO": {
    "en": "Colorado",
    "ja": "コロラド",
  },
  "CT": {
    "en": "Connecticut",
    "ja": "コネチカット",
  },
  "DE": {
    "en": "Delaware",
    "ja": "デラウェア",
  },
  "DC": {
    "en": "District Of Columbia",
    "ja": "コロンビア特別区",
  },
  "FM": {
    "en": "Federated States of Micronesia",
    "ja": "ミクロネシア連邦",
  },
  "FL": {
    "en": "Florida",
    "ja": "フロリダ",
  },
  "GA": {
    "en": "Georgia",
    "ja": "ジョージア",
  },
  "GU": {
    "en": "Guam",
    "ja": "グアム",
  },
  "HI": {
    "en": "Hawaii",
    "ja": "ハワイ",
  },
  "ID": {
    "en": "Idaho",
    "ja": "アイダホ",
  },
  "IL": {
    "en": "Illinois",
    "ja": "イリノイ",
  },
  "IN": {
    "en": "Indiana",
    "ja": "インディアナ",
  },
  "IA": {
    "en": "Iowa",
    "ja": "アイオワ",
  },
  "KS": {
    "en": "Kansas",
    "ja": "カンザス",
  },
  "KY": {
    "en": "Kentucky",
    "ja": "ケンタッキー",
  },
  "LA": {
    "en": "Louisiana",
    "ja": "ルイジアナ",
  },
  "ME": {
    "en": "Maine",
    "ja": "メイン",
  },
  "MH": {
    "en": "Marshall Islands",
    "ja": "マーシャル諸島",
  },
  "MD": {
    "en": "Maryland",
    "ja": "メリーランド",
  },
  "MA": {
    "en": "Massachusetts",
    "ja": "マサチューセッツ",
  },
  "MI": {
    "en": "Michigan",
    "ja": "ミシガン",
  },
  "MN": {
    "en": "Minnesota",
    "ja": "ミネソタ",
  },
  "MS": {
    "en": "Mississippi",
    "ja": "ミシシッピ",
  },
  "MO": {
    "en": "Missouri",
    "ja": "ミズーリ",
  },
  "MT": {
    "en": "Montana",
    "ja": "モンタナ",
  },
  "NE": {
    "en": "Nebraska",
    "ja": "ネブラスカ",
  },
  "NV": {
    "en": "Nevada",
    "ja": "ネバダ",
  },
  "NH": {
    "en": "New Hampshire",
    "ja": "ニューハンプシャー",
  },
  "NJ": {
    "en": "New Jersey",
    "ja": "ニュージャージー",
  },
  "NM": {
    "en": "New Mexico",
    "ja": "ニューメキシコ",
  },
  "NY": {
    "en": "New York",
    "ja": "ニューヨーク",
  },
  "NC": {
    "en": "North Carolina",
    "ja": "ノースカロライナ",
  },
  "ND": {
    "en": "North Dakota",
    "ja": "ノースダコタ",
  },
  "MP": {
    "en": "Northern Mariana Islands",
    "ja": "北マリアナ諸島",
  },
  "OH": {
    "en": "Ohio",
    "ja": "オハイオ",
  },
  "OK": {
    "en": "Oklahoma",
    "ja": "オクラホマ",
  },
  "OR": {
    "en": "Oregon",
    "ja": "オレゴン",
  },
  "PW": {
    "en": "Palau",
    "ja": "パラオ",
  },
  "PA": {
    "en": "Pennsylvania",
    "ja": "ペンシルベニア",
  },
  "PR": {
    "en": "Puerto Rico",
    "ja": "プエルトリコ",
  },
  "RI": {
    "en": "Rhode Island",
    "ja": "ロードアイランド",
  },
  "SC": {
    "en": "South Carolina",
    "ja": "サウスカロライナ",
  },
  "SD": {
    "en": "South Dakota",
    "ja": "サウスダコタ",
  },
  "TN": {
    "en": "Tennessee",
    "ja": "テネシー",
  },
  "TX": {
    "en": "Texas",
    "ja": "テキサス",
  },
  "UT": {
    "en": "Utah",
    "ja": "ユタ",
  },
  "VT": {
    "en": "Vermont",
    "ja": "バーモント",
  },
  "VA": {
    "en": "Virginia",
    "ja": "バージニア",
  },
  "WA": {
    "en": "Washington",
    "ja": "ワシントン",
  },
  "WV": {
    "en": "West Virginia",
    "ja": "ウェストバージニア",
  },
  "WI": {
    "en": "Wisconsin",
    "ja": "ウィスコンシン",
  },
  "WY": {
    "en": "Wyoming",
    "ja": "ワイオミング",
  },
};

export const CURRENCIES: {
  [key: string]: {
    currency: string;
    mark: string;
    isPositionPrev: boolean;
  };
} = {
  "US": {
    "currency": "usd",
    "mark": "$",
    "isPositionPrev": true,
  },
};

export const TRANSLATION_FIELDS: {
  [key: string]: string[];
} = {
  "sake": ["name", "description", "rice", "sakeType", "yeast", "breweryName", "prefecture"],
  "review": ["name", "description", "sakeName"],
  "brewery": ["name", "description", "prefecture"],
  "article": ["title", "description", "content"],
};

export const ISO_LANG: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  en: {
    en: "English",
    ja: "Japanese",
    zh: "Chinese",
    ko: "Korean",
    fr: "French",
    es: "Spanish",
    de: "German",
    it: "Italian",
    ru: "Russian",
    pt: "Portuguese",
    vi: "Vietnamese",
    th: "Thai",
    id: "Indonesian",
    ar: "Arabic",
    hi: "Hindi",
  },
  ja: {
    en: "英語",
    ja: "日本語",
    zh: "中国語",
    ko: "韓国語",
    fr: "フランス語",
    es: "スペイン語",
    de: "ドイツ語",
    it: "イタリア語",
    ru: "ロシア語",
    pt: "ポルトガル語",
    vi: "ベトナム語",
    th: "タイ語",
    id: "インドネシア語",
    ar: "アラビア語",
    hi: "ヒンディー語",
  },
};
