import Body from "./Body.server";
import { getReview } from '@/services/review';

export default async function ReviewContentServer({
  value,
}: {
  value: string;
}) {
  const { result: review } = await getReview(value);

  return <Body review={review} />
}
