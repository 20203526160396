"use client";

import { ReviewItemClient } from "@/components";
import { Review } from "@/types";

export default function Body({
  review,
}: {
  review: Review;
}) {
  return (
    <div className="my-4">
      <ReviewItemClient review={review} as="div" />
    </div>
  );
}
