import { withLocale } from "./withLocale";

export const host = process.env.NEXT_PUBLIC_URL || "http://localhost:3000";

export const path = async (pathname?: string) => {
  const url = `${host}${pathname}`;
  const res = await withLocale(url)
  return res;
}

export class FetchError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

export const handleSucceed = async (res: Response) => {
  const data = await res.json();
  if (!res.ok) {
    throw new FetchError(res.statusText, res.status);
  }
  return data;
};

export const handleFailed = async (err: unknown) => {
  if (err instanceof FetchError) {
  }
  throw err;
};
