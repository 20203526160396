"use client";

import { useState } from "react";
import { useTranslations } from 'next-intl';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { validationContactSchema } from "@/utils/validationSchema";
import { postIncomingWebhook } from "@/services/slack";

export default function ContactForm() {
  const [isSent, setIsSent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(validationContactSchema),
  });
  const t = useTranslations("Contact");

  const handleSend = async (data: any) => {
    const { name, email, message } = data;
    await postIncomingWebhook({ name, email, message })
    setIsSent(true);
  };

  if (isSent) return <p className="text-center text-lg font-semibold">{t("successMessage")}</p>;

  return (
    <form
      onSubmit={handleSubmit(handleSend)}
      className="flex flex-col gap-4"
    >
      <div>
        <input
          type="text"
          id="name"
          placeholder={t("name")}
          className="input input-bordered w-full rounded-md"
          {...register("name", {})}
        />
        <p className="text-error">{errors.name?.message as React.ReactNode}</p>
      </div>
      <div>
        <input
          type="email"
          id="email"
          placeholder={t("email")}
          className="input input-bordered w-full rounded-md"
          {...register("email", {})}
        />
        <p className="text-error">{errors.email?.message as React.ReactNode}</p>
      </div>
      <div>
        <textarea
          id="message"
          className="textarea textarea-bordered w-full rounded-md"
          placeholder={t("message")}
          {...register("message", {})}
        ></textarea>
        <p className="text-error">{errors.message?.message as React.ReactNode}</p>
      </div>
      <button type="submit" className="btn btn-primary text-white">{t("send")}</button>
    </form>
  );
}
