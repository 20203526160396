import { getTranslations } from 'next-intl/server';
import {
  TAG_COLORS,
  TAG_TRANSLATION,
} from "@/utils/constants";

type Props = {
  lessStrongAroma?: number;
  drySweet?: number;
  lightRich?: number;
  aromaKeywords?: string[];
  tasteKeywords?: string[];
  locale?: string;
};

export default async function Characteristic({
  lessStrongAroma,
  drySweet,
  lightRich,
  aromaKeywords,
  tasteKeywords,
  locale,
}: Props) {
  const t = await getTranslations('Characteristic');
  return (
    <div className="py-4">
      {lessStrongAroma || drySweet || lightRich ? (
        <>
          <div className="flex items-center w-8/12 sp:w-full">
            <p className="text-lg whitespace-nowrap min-w-32 sp:text-base sp:min-w-24">{t("lessAroma")}</p>
            <div className="w-full h-2 bg-custom-gray bg-opacity-60 rounded-full relative">
              {lessStrongAroma && (
                <div
                  className={`absolute top-1/2 -translate-y-1/2 h-2 w-2 rounded-full bg-custom-red left-4`}
                  style={{ left: `${lessStrongAroma * 10}%` }}
                >
                </div>
              )}
            </div>
            <p className="text-lg whitespace-nowrap min-w-24 ml-8 sp:text-base sp:min-w-16 sp:ml-3">{t("strong")}</p>
          </div>
          <div className="flex items-center w-8/12 mt-6 sp:w-full sp:mt-4">
            <p className="text-lg whitespace-nowrap min-w-32 sp:text-base sp:min-w-24">{t("dry")}</p>
            <div className="w-full h-2 bg-custom-gray bg-opacity-60 rounded-full relative">
              {drySweet && (
                <div
                  className={`absolute top-1/2 -translate-y-1/2 h-2 w-2 rounded-full bg-custom-red left-4`}
                  style={{ left: `${drySweet * 10}%` }}
                >
                </div>
              )}
            </div>
            <p className="text-lg whitespace-nowrap min-w-24 ml-8 sp:text-base sp:min-w-16 sp:ml-3">{t("sweet")}</p>
          </div>
          <div className="flex items-center w-8/12 mt-6 sp:w-full sp:mt-4">
            <p className="text-lg whitespace-nowrap min-w-32 sp:text-base sp:min-w-24">{t("light")}</p>
            <div className="w-full h-2 bg-custom-gray bg-opacity-60 rounded-full relative">
              {lightRich && (
                <div
                  className={`absolute top-1/2 -translate-y-1/2 h-2 w-2 rounded-full bg-custom-red left-4`}
                  style={{ left: `${lightRich * 10}%` }}
                >
                </div>
              )}
            </div>
            <p className="text-lg whitespace-nowrap min-w-24 ml-8 sp:text-base sp:min-w-16 sp:ml-3">{t("rich")}</p>
          </div>
        </>
      ) : (
        <div className="text-lg text-custom-gray sp:text-base">{t("noData")}</div>
      )}
      {((aromaKeywords && aromaKeywords.length > 0) || (tasteKeywords && tasteKeywords.length > 0)) && (
        <ul className="flex flex-wrap gap-4 mt-6 sp:gap-2 sp:mt-4">
          {[...(aromaKeywords || []), ...(tasteKeywords || [])].map((keyword) => {
            const tt = TAG_TRANSLATION[keyword];
            const text = tt ? (tt[locale || "en"] || keyword) : keyword;
            return (
              <li
                key={keyword}
                className="text-sm bg-custom-brown rounded-full py-1 px-3 sp:text-xs sp:px-2"
                style={{ backgroundColor: TAG_COLORS[keyword] }}
              >
                {text}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
