'use client';

import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { Article, ArticleContent, HeadingContent, SubHeadingContent } from "@/types";

export default function TableOfContentsContentClient({
  article,
  locale,
}: {
  article: Article;
  locale: string;
}) {
  const t = useTranslations('Article');
  const headings = (locale === "en" ? article.content : (article.translations?.content?.[locale] || []) as ArticleContent[]).filter((c) => ["HEADING", "SUB_HEADING"].includes(c.type)) as (HeadingContent | SubHeadingContent)[];

  return (
    <div className="flex justify-center w-full my-8">
      <div className="w-full max-w-[540px] bg-gray-100 p-4 rounded-md">
        <p className="font-semibold text-lg underline">{t("tableOfContents")}</p>
        <ol className="list-decimal list-inside text-md leading-relaxed font-extralight text-secondary mt-2 ml-2">
          {headings.map((h) => {
            if (h.type === "HEADING") {
              return (
                <li key={h.id} className="mt-1">
                  <Link href={`#${h.id}`}>{h.value}</Link>
                </li>
              );
            } else if (h.type === "SUB_HEADING") {
              return (
                <ul key={h.id} className="list-disc list-inside ml-4">
                  <li>
                    <Link href={`#${h.id}`}>{h.value}</Link>
                  </li>
                </ul>
              );
            }
            return null;
          })}
        </ol>
      </div>
    </div>
  );
}
