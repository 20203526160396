import { Link } from '@/navigation';
import {
  SignInButton,
  ShipToButton,
  LocaleSwitcher,
  CartButton,
  HamburgerMenu,
} from "@/components";
import Image from "next/image";

export default function Header() {
  return (
    <header className="mb-4 py-3 px-6 flex justify-between items-center sp:mb-2 sp:py-1 sp:px-4 tb:px-8">
      <div className="flex items-center">
        <HamburgerMenu />
        <Link href="/" title="HelloSake" className="flex items-center">
          <Image
            src="/images/logo.avif"
            alt="HelloSake"
            title="HelloSake"
            width={14}
            height={34}
          />
          <h1 className="font-semibold text-2xl ml-2 sp:text-xl">HelloSake</h1>
        </Link>
      </div>
      <div className="flex items-center pr-2">
        <div className="flex items-center gap-x-2 sp:gap-x-1">
          <ShipToButton />
          <LocaleSwitcher />
        </div>
        <div className="flex items-center gap-x-4 ml-14 sp:ml-2 sp:gap-x-1">
          <SignInButton />
          <CartButton />
        </div>
      </div>
    </header>
  );
}
