export default function SubHeadingContent({
  value,
  id,
}: {
  value: string;
  id: string,
}) {
  return (
    <h3 id={id} className="font-semibold text-xl mt-6 mb-1 sp:text-base sp:mt-4">{value}</h3>
  );
}
