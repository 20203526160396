"use client";

import { useTranslations } from 'next-intl';

type Props = {
  rating: number | undefined;
};

export default function RatingStarClient({
  rating,
}: Props) {
  const t = useTranslations('Others');
  if (!rating) {
    return <div className="text-md text-custom-gray whitespace-nowrap">{t("noRating")}</div>;
  }
  return (
    <div className="rating rating-sm rating-half sp:rating-xs">
      <input type="radio" className={`mask mask-star-2 mask-half-1 ${rating > 0 ? "bg-primary" : "bg-custom-gray"}`} />
      <input type="radio" className={`mask mask-star-2 mask-half-2 ${rating > 0.7 ? "bg-primary" : "bg-custom-gray"}`} />
      <input type="radio" className={`mask mask-star-2 mask-half-1 ${rating > 1.2 ? "bg-primary" : "bg-custom-gray"}`} />
      <input type="radio" className={`mask mask-star-2 mask-half-2 ${rating > 1.7 ? "bg-primary" : "bg-custom-gray"}`} />
      <input type="radio" className={`mask mask-star-2 mask-half-1 ${rating > 2.2 ? "bg-primary" : "bg-custom-gray"}`} />
      <input type="radio" className={`mask mask-star-2 mask-half-2 ${rating > 2.7 ? "bg-primary" : "bg-custom-gray"}`} />
      <input type="radio" className={`mask mask-star-2 mask-half-1 ${rating > 3.2 ? "bg-primary" : "bg-custom-gray"}`} />
      <input type="radio" className={`mask mask-star-2 mask-half-2 ${rating > 3.7 ? "bg-primary" : "bg-custom-gray"}`} />
      <input type="radio" className={`mask mask-star-2 mask-half-1 ${rating > 4.2 ? "bg-primary" : "bg-custom-gray"}`} />
      <input type="radio" className={`mask mask-star-2 mask-half-2 ${rating > 4.7 ? "bg-primary" : "bg-custom-gray"}`} />
    </div>
  );
}
