import { handleFailed, handleSucceed, path } from "../";
import { User } from '@/types';
import {
  SHIP_TO_COUNTRIES,
  STATES,
} from "@/utils/constants";
//import { z } from "zod"
//import { accountSchema } from "@/lib/zod";

// formのlibraryの制約がserviceレイヤーに影響を与えるべきではないため、別途Paramsを定義する
//type Params = z.infer<typeof accountSchema>;
interface Params {
  name?: string;
  biography?: string;
  licenses?: string[];
  image?: string | ArrayBuffer | null;
  fileName?: string;
  shipCountry?: keyof typeof SHIP_TO_COUNTRIES;
  shipState?: keyof typeof STATES;
  isAgeConfirmed?: boolean;
}

export async function updateUser(params: Params): Promise<{ result: User }> {
  return fetch(await path("/api/users"), {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params),
  })
    .then(handleSucceed)
    .catch(handleFailed);
}
