"use client";

import Body from "./Body.client";
import { useSake } from "@/hooks/useSake";

export default function SakeContent({
  value,
}: {
  value: string;
}) {
  const { data: sake, isLoading } = useSake(value)

  if (isLoading || !sake) return null;

  return <Body sake={sake} />;
}
