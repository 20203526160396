import { getTranslations } from 'next-intl/server';
import { Sake } from "@/types";
import Image from "next/image";
import { Link } from '@/navigation';

type Props = {
  sakes: Sake[];
};

export default async function PositioningMap({
  sakes,
}: Props) {
  const t = await getTranslations("SakePositioningMap");
  const validSakes = sakes.filter((sake): sake is Sake & { lightRich: number; lessStrongAroma: number } => {
    return (!!sake.lessStrongAroma || sake.lessStrongAroma === 0) && (!!sake.lightRich || sake.lightRich === 0);
  });
  return (
    <div className="p-4">
      <div className="inline-flex flex-col text-sm font-medium relative">
        <div className="flex border-b border-black">
          <div className="h-48 w-48 border-r border-black relative sp:h-40 sp:w-40">
            <p className="absolute left-4 bottom-0 z-10 bg-white bg-opacity-80">{t("lessAroma")}</p>
          </div>
          <div className="h-48 w-48 relative sp:h-40 sp:w-40">
            <p className="absolute left-4 top-0 z-10 bg-white bg-opacity-80">{t("richFlavor")}</p>
            <p className="absolute right-0 bottom-0 z-10 bg-white bg-opacity-80">{t("strongAroma")}</p>
          </div>
        </div>
        <div className="flex">
          <div className="h-48 w-48 border-r border-black sp:h-40 sp:w-40"></div>
          <div className="h-48 w-48 relative sp:h-40 sp:w-40">
            <p className="absolute left-4 bottom-0 z-10 bg-white bg-opacity-80">{t("lightFlavor")}</p>
          </div>
        </div>
        {validSakes.map((sake) => (
          <div
            key={sake.id}
            className={`absolute tooltip ${sake.lightRich > 5 ? "translate-y-1/2" : "-translate-y-1/2"} ${sake.lessStrongAroma ? "translate-x-1/2" : "-translate-x-1/2"}`}
            data-tip={sake.name}
            style={{
              "bottom": `${sake.lightRich * 10}%`,
              "left": `${sake.lessStrongAroma * 10}%`,
            }}
          >
            <Link href={`/products/${sake.id}`} title={sake.name}>
              <Image
                src={sake.labelImageUrl ? `${process.env.NEXT_PUBLIC_SAKE_LABEL_IMAGE_URL_BASE}${sake.labelImageUrl}` : "/images/no-image-sake.png"}
                width={14}
                height={14}
                alt={sake.name}
                title={sake.name}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
