import { ISO_LANG } from "@/utils/constants";

export function getLangAnnotation(originalLang: string | undefined, lang: string) {
  const iso = ISO_LANG[lang];
  // 現在表示しているlangとレビューの元言語が異なるかどうか（同じ場合は注釈不要）
  const shouldPresentAnnotation = originalLang !== lang;
  const langText = shouldPresentAnnotation ? iso[originalLang as string] || "" : "";

  return { shouldPresentAnnotation, langText };
}
