import Body from "./Body.server";
import { getSake } from "@/services/sake";

export default async function SakeContent({
  value,
}: {
  value: string;
}) {
  const { result: sake } = await getSake(value);

  return <Body sake={sake} />;
}
