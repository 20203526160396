import { Review } from '@/types';
import useSWR from 'swr';
import { useLocale } from 'next-intl';

const fetcher = (url: string): Promise<Review> =>
  fetch(url)
    .then((res) => res.json())
    .then((res) => res.result);

export const useReview = (id: string) => {
  const locale = useLocale() || "en";
  const res = useSWR<Review>(`/api/reviews/${id}?locale=${locale}`, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  return res;
};
