import { getTranslations } from 'next-intl/server';
import Image from "next/image";
import { PARING_IMAGES, PARING_TRANSLATION } from "@/utils/constants";

type Props = {
  bestFoodPairings?: string[];
  locale?: string;
};

export default async function Pairing({
  bestFoodPairings,
  locale,
}: Props) {
  const t = await getTranslations('Pairing');
  return (
    <div className="py-4">
      {!bestFoodPairings || bestFoodPairings.length === 0 ? (
        <div className="text-lg text-custom-gray sp:text-base">{t("noData")}</div>
      ) : (
        <ul className="flex flex-wrap gap-4 sp:gap-2">
          {[...(bestFoodPairings || [])].map((paring) => {
            const pt = PARING_TRANSLATION[paring];
            const text = pt ? (pt[locale || "en"] || paring) : paring;
            return (
              <li
                key={paring}
                className="flex flex-col items-center w-24 sp:w-20"
              >
                <Image
                  src={PARING_IMAGES[paring] || "/images/no-image.png"}
                  width={96}
                  height={60}
                  alt={paring}
                  title={paring}
                  unoptimized
                />
                <p className="text-center font-medium leading-tight mt-1 sp:text-sm sp:font-normal">{text}</p>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
