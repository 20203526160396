'use client';

import React, { useState } from "react";
import { useLocale, useTranslations } from 'next-intl';
import Image from "next/image";
import {
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import {
  SHIP_TO_COUNTRIES,
  STATES,
} from "@/utils/constants";
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { updateUser } from "@/services/user";

export default function ShipToButton() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const { data: currentUser, isLoading, mutate } = useCurrentUser();
  const t = useTranslations('Header');
  const locale = useLocale() || "en";

  const shipCountry = currentUser?.shipCountry;
  const shipState = currentUser?.shipState;

  const handleSelectCountry = async (value: keyof typeof SHIP_TO_COUNTRIES) => {
    await updateUser({ shipCountry: value });
    await mutate();
  };

  const handleSelectState = async (value: keyof typeof STATES) => {
    await updateUser({ shipState: value });
    await mutate();
  };

  return (
    <div className="flex items-center gap-x-4 sp:hidden">
      <div className="dropdown dropdown-end">
        <div
          tabIndex={0}
          role="button"
          className="flex items-center gap-x-1 cursor-pointer"
          onClick={() => setIsDropdownOpen(prev => !prev)}
        >
          <div>
            <p className="text-xs text-neutral font-light">{t("shipTo")}</p>
            {!isLoading ? <p className="font-light">{shipCountry ? SHIP_TO_COUNTRIES[shipCountry].name[locale] : t("clickToSelect")}</p> : (
              <span className="loading loading-spinner loading-xs text-secondary"></span>
            )}
          </div>
          <ChevronDownIcon width="16" height="16" />
        </div>
        {isDropdownOpen && (
          <ul tabIndex={0} className="dropdown-content bg-base-100 rounded-box rounded-md z-[1] w-56 p-2 shadow">
            {Object.keys(SHIP_TO_COUNTRIES).map((value) => {
              const data = SHIP_TO_COUNTRIES[value];
              return (
                <li
                  key={value}
                  className="flex items-center text-sm font-extralight p-2 rounded-lg cursor-pointer group"
                  onClick={async () => {
                    await handleSelectCountry(value);
                    setIsDropdownOpen(false);
                  }}
                >
                  {data.icon && (
                    <Image
                      src={data.icon}
                      width={20}
                      height={20}
                      alt={data.name[locale]}
                      title={data.name[locale]}
                      unoptimized
                      className="mr-2"
                    />
                  )}
                  <p className={`${shipCountry === value ? "font-medium" : ""} group-hover:underline hover:text-secondary`}>{data.name[locale]}</p>
                  {data.comingSoon && <p className="text-xs text-neutral grow text-right">{t("comingSoon")}</p>}
                </li>
              )
            })}
          </ul>
        )}
      </div>
      {shipCountry === "US" && (
        <div className="dropdown dropdown-end">
          <div
            tabIndex={1}
            role="button"
            className="flex items-center gap-x-1 cursor-pointer"
            onClick={() => setIsStateDropdownOpen(prev => !prev)}
          >
            <div>
              <p className="text-xs text-neutral font-light">{t("state")}</p>
              {!isLoading ? <p className="font-light">{shipState ? STATES[shipState][locale] : t("clickToSelect")}</p> : (
                <span className="loading loading-spinner loading-xs text-secondary"></span>
              )}
            </div>
            <ChevronDownIcon width="16" height="16" />
          </div>
          {isStateDropdownOpen && (
            <ul tabIndex={0} className="dropdown-content bg-base-100 rounded-box rounded-md z-[1] w-96 p-2 shadow grid grid-cols-3 gap-1">
              {Object.keys(STATES).map((value) => (
                <li
                  key={value}
                  className="text-sm font-extralight p-1 rounded-lg cursor-pointer hover:text-secondary hover:underline"
                  onClick={async () => {
                    await handleSelectState(value);
                    setIsStateDropdownOpen(false);
                  }}
                >
                  <p className={shipState === value ? "font-medium" : ""}>{STATES[value][locale]}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
