"use client";

import { useState, useEffect } from "react";
import { useTranslations } from 'next-intl';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { validationContactSchema } from "@/utils/validationSchema";
import { postIncomingWebhook } from "@/services/slack";
import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  FlagIcon,
} from "@heroicons/react/24/outline";

export default function ReportError() {
  const [isSent, setIsSent] = useState(false);
  const { data: currentUser } = useCurrentUser();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(validationContactSchema),
    defaultValues: {
      name: currentUser?.name ?? "",
      email: currentUser?.email ?? "",
      message: "",
    }
  });
  const t = useTranslations("Contact");

  useEffect(() => {
    if (currentUser) {
      reset({
        name: currentUser.name ?? "",
        email: currentUser.email ?? "",
        message: "",
      });
    }
  }, [currentUser, reset]);


  const handleOpen = () => {
    const modal = document.querySelector<HTMLDialogElement>('#report-error');
    modal?.showModal()
  };

  const handleSend = async (data: any) => {
    const { message } = data;
    const name = data.name ?? currentUser?.name ?? "NoName";
    const email = data.email ?? currentUser?.email ?? "-";
    await postIncomingWebhook({ name, email, message: `${message}\n\n問い合わせ元ページ： ${window.location.href}` })
    setIsSent(true);
  };

  return (
    <>
      <div
        onClick={handleOpen}
        className="flex items-center text-sm font-light text-neutral underline hover:text-secondary cursor-pointer"
      >
        <FlagIcon className="h-4 w-4 mr-1" />
        {t("reportErrorLinkText")}
      </div>
      <dialog id="report-error" className="modal">
        <div className="modal-box w-10/12 max-w-3xl sp:w-11/12 sp:px-1">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>
          {isSent ? (
            <p className="text-center text-lg font-semibold">{t("reportErrorSuccessMessage")}</p>
          ) : (
            <div>
              <p className="mb-4 mt-2">{t("reportErrorDescription")}</p>
              <form
                onSubmit={handleSubmit(handleSend)}
                className="flex flex-col gap-4"
              >
                <div>
                  <input
                    type="text"
                    id="name"
                    placeholder={t("name")}
                    className="input input-bordered w-full rounded-md"
                    {...register("name", {})}
                  />
                  <p className="text-error">{errors.name?.message as React.ReactNode}</p>
                </div>
                <div>
                  <input
                    type="email"
                    id="email"
                    placeholder={t("email")}
                    className="input input-bordered w-full rounded-md"
                    {...register("email", {})}
                  />
                  <p className="text-error">{errors.email?.message as React.ReactNode}</p>
                </div>
                <div>
                  <textarea
                    id="message"
                    className="textarea textarea-bordered w-full rounded-md"
                    placeholder={t("message")}
                    {...register("message", {})}
                  ></textarea>
                  <p className="text-error">{errors.message?.message as React.ReactNode}</p>
                </div>
                <button type="submit" className="btn btn-primary text-white">{t("send")}</button>
              </form>
            </div>
          )}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>{t("close")}</button>
        </form>
      </dialog>
    </>
  );
};
