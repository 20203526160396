import { getTranslations } from 'next-intl/server';
import { useTranslations } from 'next-intl';

export const timeAgo = async (dateString: string): Promise<string> => {
  if (!dateString) return "-";

  const t = await getTranslations('Others');
  const now = new Date();
  const past = new Date(dateString);

  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays}${t("daysAgo")}`;
  } else if (diffInHours > 0) {
    return `${diffInHours}${t("hoursAgo")}`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes}${t("minutesAgo")}`;
  } else {
    return `${diffInSeconds}${t("secondsAgo")}`;
  }
};

export function useTimeAgoClient(dateString: string): string {
  const t = useTranslations('Others');

  if (!dateString) return "-";

  const now = new Date();
  const past = new Date(dateString);

  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays}${t("daysAgo")}`;
  } else if (diffInHours > 0) {
    return `${diffInHours}${t("hoursAgo")}`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes}${t("minutesAgo")}`;
  } else {
    return `${diffInSeconds}${t("secondsAgo")}`;
  }
};

export const formatDate = (dateString: string): string => {
  if (!dateString) return "-";

  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  return formattedDate;
};
